<template>
  <div class="ibox deployment-site">
    <div class="ibox-title">
      <h2>{{ $t('vigneron-online.deployment.shop-content.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>{{ $t('vigneron-online.deployment.shop-content.note') }}</p>

      <form role="form" @submit="onFormSubmit" name="publish-shop-content">
        <button class="btn btn-primary ladda-button publish-shop-content" data-style="zoom-in" type="submit">{{ $t('vigneron-online.deployment.shop-content.button') }}</button>
      </form>

    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

import { useVigneronOnlineAdmin } from '../../../composables/useVigneronOnlineAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { selectedShopContent } = useVigneronOnlineAdmin(props, context);


      const listLoading:Ref<boolean> = ref(false);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-shop-content] button.publish-shop-content' );
        laddaSubmit = Ladda.create(submitButton!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        var input = {}

        var options:api.ApiVueOptions =  {
          app: app
        }

        laddaSubmit!.start();

        var path = '/api/admin/vigneron-online/shop-content/'+selectedShopContent.value.shopContent._id+'/deployment/publish-shop-content';
        
        api.postAPI(path, input, options).then((response:any) => {
          if(response.published && response.shopContent) {  
            // We emit event
            context.emit('shop-content-published', response.shopContent);
          }

          laddaSubmit!.stop();
        });
        
      }

        return { 
          listLoading,
          onFormSubmit
        }
    }
})
</script>