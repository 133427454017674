<template>
  <div class="ibox deployment-customization">
    <div class="ibox-title">
      <h2>{{ $t('vigneron-online.deployment.validation.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>{{ $t('vigneron-online.deployment.validation.note') }}</p>

      <a class="btn btn-primary" :href="shopContentValidationLink" target="_blank">{{$t('vigneron-online.deployment.validation.button-shop-content')}}</a>

    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useVigneronOnlineAdmin } from '../../../composables/useVigneronOnlineAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { selectedShopContent, getShopContentValidationLink } = useVigneronOnlineAdmin(props, context);

      const listLoading:Ref<boolean> = ref(false);

      const shopContentValidationLink = computed(() => {
        return getShopContentValidationLink(selectedShopContent.value.shopContent);
      })

      return { 
        listLoading,
        shopContentValidationLink
      }
    }
})
</script>