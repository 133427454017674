<template>
  <section id="deployment" ref="deploymentContent">
    <PageTitle :title="$t('vigneron-online.deployment.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedShopContent">
        <div class="col">
          <Validation></Validation>
        </div>
      </div>

      <!--
      <div class="row" v-if="shopContentOptions.isShopContentDeploymentAllowed && shopContent != null">
        <div class="col">
          <Theme :shopContent="shopContent" :productionShopContent="productionShopContent"></Theme>
        </div>
      </div>
      -->

      <div class="row" v-if="selectedShopContent && selectedShopContent.options.isShopContentDeploymentAllowed">
        <div class="col">
          <ShopContent></ShopContent>
        </div>
      </div>  

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useVigneronOnlineAdmin } from '../../composables/useVigneronOnlineAdmin';

import Validation from '../panels/deployment/Validation.vue';
import Theme from '../panels/deployment/Theme.vue';
import ShopContent from '../panels/deployment/ShopContent.vue';
import Header from '../panels/Header.vue';

@Component({
  components: {
    PageTitle,
    Validation,
    Theme,
    Header,
    ShopContent
  }
})
export default class Deployment extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { shopContents, selectedShopContent } = useVigneronOnlineAdmin(props, context);
    return { shopContents, selectedShopContent }
  }

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('vigneron-online.shop-content.title')
    }]
  }

  mounted() {
    enableIbox(this.$refs.deploymentContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.deploymentContent as HTMLElement);
  }

}
</script>